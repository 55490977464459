import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _202dcc8a = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5b08ed63 = () => interopDefault(import('../pages/blogs.vue' /* webpackChunkName: "pages/blogs" */))
const _f50aef84 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _1c68f8ba = () => interopDefault(import('../pages/categories.vue' /* webpackChunkName: "pages/categories" */))
const _4cbdb738 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _e313c2ee = () => interopDefault(import('../pages/connect-on-whatsapp-with-mochi.vue' /* webpackChunkName: "pages/connect-on-whatsapp-with-mochi" */))
const _ea07daee = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _3368a6da = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _a8a5ee22 = () => interopDefault(import('../pages/franchise.vue' /* webpackChunkName: "pages/franchise" */))
const _8d6ba88a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1fb2f1cf = () => interopDefault(import('../pages/loyalty-program.vue' /* webpackChunkName: "pages/loyalty-program" */))
const _1db91459 = () => interopDefault(import('../pages/masks.vue' /* webpackChunkName: "pages/masks" */))
const _2ef6d788 = () => interopDefault(import('../pages/pagenotfound.vue' /* webpackChunkName: "pages/pagenotfound" */))
const _200ac822 = () => interopDefault(import('../pages/pending-payment.vue' /* webpackChunkName: "pages/pending-payment" */))
const _56b9ad35 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _1cd086f2 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _357dd8ea = () => interopDefault(import('../pages/sustainable-shoes.vue' /* webpackChunkName: "pages/sustainable-shoes" */))
const _1df116da = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _61047e3c = () => interopDefault(import('../pages/account/address-book.vue' /* webpackChunkName: "pages/account/address-book" */))
const _2bbffc06 = () => interopDefault(import('../pages/account/coupons.vue' /* webpackChunkName: "pages/account/coupons" */))
const _3401ce04 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _0d95c92b = () => interopDefault(import('../pages/account/mochi-loyalty.vue' /* webpackChunkName: "pages/account/mochi-loyalty" */))
const _1e067870 = () => interopDefault(import('../pages/account/order-detail.vue' /* webpackChunkName: "pages/account/order-detail" */))
const _113a2f25 = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _32ccebf0 = () => interopDefault(import('../pages/account/return.vue' /* webpackChunkName: "pages/account/return" */))
const _a174aff6 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _53cf79b7 = () => interopDefault(import('../pages/blog/_blogView.vue' /* webpackChunkName: "pages/blog/_blogView" */))
const _d48a01b6 = () => interopDefault(import('../pages/page/_index.vue' /* webpackChunkName: "pages/page/_index" */))
const _75077e24 = () => interopDefault(import('../pages/product/_product.vue' /* webpackChunkName: "pages/product/_product" */))
const _49c68521 = () => interopDefault(import('../pages/st-search/_slug.vue' /* webpackChunkName: "pages/st-search/_slug" */))
const _20baeeb8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _69d9e3af = () => interopDefault(import('../pages/_category.vue' /* webpackChunkName: "pages/_category" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _202dcc8a,
    name: "404"
  }, {
    path: "/blogs",
    component: _5b08ed63,
    name: "blogs"
  }, {
    path: "/cart",
    component: _f50aef84,
    name: "cart"
  }, {
    path: "/categories",
    component: _1c68f8ba,
    name: "categories"
  }, {
    path: "/checkout",
    component: _4cbdb738,
    name: "checkout"
  }, {
    path: "/connect-on-whatsapp-with-mochi",
    component: _e313c2ee,
    name: "connect-on-whatsapp-with-mochi"
  }, {
    path: "/contact-us",
    component: _ea07daee,
    name: "contact-us"
  }, {
    path: "/error",
    component: _3368a6da,
    name: "error"
  }, {
    path: "/franchise",
    component: _a8a5ee22,
    name: "franchise"
  }, {
    path: "/login",
    component: _8d6ba88a,
    name: "login"
  }, {
    path: "/loyalty-program",
    component: _1fb2f1cf,
    name: "loyalty-program"
  }, {
    path: "/masks",
    component: _1db91459,
    name: "masks"
  }, {
    path: "/pagenotfound",
    component: _2ef6d788,
    name: "pagenotfound"
  }, {
    path: "/pending-payment",
    component: _200ac822,
    name: "pending-payment"
  }, {
    path: "/privacy-policy",
    component: _56b9ad35,
    name: "privacy-policy"
  }, {
    path: "/sitemap",
    component: _1cd086f2,
    name: "sitemap"
  }, {
    path: "/sustainable-shoes",
    component: _357dd8ea,
    name: "sustainable-shoes"
  }, {
    path: "/thankyou",
    component: _1df116da,
    name: "thankyou"
  }, {
    path: "/account/address-book",
    component: _61047e3c,
    name: "account-address-book"
  }, {
    path: "/account/coupons",
    component: _2bbffc06,
    name: "account-coupons"
  }, {
    path: "/account/dashboard",
    component: _3401ce04,
    name: "account-dashboard"
  }, {
    path: "/account/mochi-loyalty",
    component: _0d95c92b,
    name: "account-mochi-loyalty"
  }, {
    path: "/account/order-detail",
    component: _1e067870,
    name: "account-order-detail"
  }, {
    path: "/account/orders",
    component: _113a2f25,
    name: "account-orders"
  }, {
    path: "/account/return",
    component: _32ccebf0,
    name: "account-return"
  }, {
    path: "/account/wishlist",
    component: _a174aff6,
    name: "account-wishlist"
  }, {
    path: "/blog/:blogView?",
    component: _53cf79b7,
    name: "blog-blogView"
  }, {
    path: "/page/:index",
    component: _d48a01b6,
    name: "page"
  }, {
    path: "/product/:product?",
    component: _75077e24,
    name: "product-product"
  }, {
    path: "/st-search/:slug?",
    component: _49c68521,
    name: "st-search-slug"
  }, {
    path: "/",
    component: _20baeeb8,
    name: "index"
  }, {
    path: "/:category",
    component: _69d9e3af,
    name: "category"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
