var list = {
    summary: {},
    wishlist: [],
    base_Url: "https://walkwayshoes.com",
    optimiseLink: false,
    // availableLoyalty: null,
    breadCrumbArray: [],
    // capillaryInfo: {
    //     customer: [{
    //         loyalty_points: null
    //     }]
    // },
    capillaryAmount: null,
    topProductsCategory: '',
    topProducts: [],
    reactiveComponentCount: 0,
    footerDescription: ``,
    login_popup: false,
    error_message: '',
    success_message: '',
    page_loader: false,
    cart_product: [],
    search_input: '',
    gtm_product: [],
    menu: [],
    cart_subtotal: '',
    cart_total: '',
    pro_id_store: '',
    wishlist_count: 0,
    customer_detail: '',
    minicart: true,
    menuActive: true,
    address: [],
    select_address_no: '',
    address_id: '',
    cart_id: '',
    customer_id: null,
    customer_session: '',
    cart_session: '',
    g_customer_id: '',
    login_status: '',
    discount_code: null,
    thankyou_status: '',
    thankyou_cart: '',
    discount_amount: '',
    shipping_charges: '',
    shipping_charges_below: '',
    store: 1,
    redirectFromPDP: false,
    
    // staging
    // api_url: 'https://wk.pimkm.greenhonchos.com',
    // cart_api_url: 'https://wk.cartkm.greenhonchos.com/api/v1',
    // adminURL: 'https://wk.adminkm.greenhonchos.com/api',
    
    

    // live
    cart_api_url: 'https://cart.walkwayshoes.com/api/v1',
    api_url: 'https://pim.walkwayshoes.com',
    adminURL: 'https://admin.walkwayshoes.com/api',
    
    breadcrumb: '',
    breadcrumb_second: {
        name: '',
        url: '',
    },
    path: '',
    plpSeoContent: ''
}
var productBackPageDetail =  {}

export default {
    list
}
