//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
export default {
  name: 'notFound',
  // metaInfo: {
  //     title: 'Not Found',
     
  //   },
  data: function () {
        return {
           from_path:'',
           send_path:'',
        };
    },
    beforeRouteEnter(to, from, next) 
    {
        next((vm) => {
            vm.from_path = from.path;
        });
    },
    methods: {
      path: function(){
          if(this.from_path === '/'){
            this.send_path = this.$route.path;
          }
          else
          {
             this.send_path = this.from_path;
          }
          axios.get(this.$store.state.list.api_url+`/pim/pimresponse.php/`, {
              params: {
              service:'404',
              store:this.$store.state.list.store,
              url_key:this.send_path
            }
          })
      }
    },
    mounted() {
      setTimeout(() => {this.path();}, 0)
    }
}
