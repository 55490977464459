import Vue from 'vue'
import Slick from 'vue-slick'
import '~/node_modules/slick-carousel/slick/slick.css'

import vueSlider from 'vue-slider-component';

import Ripple from 'vue-ripple-directive';
Ripple.color = 'rgba(0, 0, 0, 0.08)';
Ripple.zIndex =999;
Vue.directive('ripple', Ripple);


Vue.component('slick',Slick)
Vue.component('vueSlider',vueSlider)

import VueToast from 'vue-toast-notification'
import '~/node_modules/vue-toast-notification/dist/theme-sugar.css'
Vue.use(VueToast);